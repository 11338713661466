import '../widgetGenerator.css'

import React, {useState} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {WidgetLabelTypes} from 'utils/label'
import {CodeSnippet} from 'widgetsGenerator/CodeSnippet'
import {GeneratorForm} from 'widgetsGenerator/GeneratorForm'
import {LogoSection} from 'widgetsGenerator/LogoSection'
import {getWidgetCodeSnippet} from 'widgetsGenerator/utils'
import {WidgetPreview} from 'widgetsGenerator/WidgetPreview'

import {Grid} from '@daedalus/atlas/helpers/Grid'

const Wrapper = styled(Grid)(
  ({theme}) =>
    css`
      height: 100%;
      background: ${theme.colors.background.neutral.c100};
    `
)

const FormSection = styled('section')(
  ({theme}) =>
    css`
      height: 100%;
      min-width: 482px;
      max-width: 482px;
      background: ${theme.colors.background.neutral.c000};
      padding: ${theme.layout.spacing.s700}px ${theme.layout.spacing.s600}px 0;
    `
)

const PreviewSection = styled('section')(
  ({theme}) =>
    css`
      padding: ${theme.layout.spacing.s900}px ${theme.layout.spacing.s900}px 0;
      background: ${theme.colors.background.neutral.c100};
    `
)

const WidgetsGenerator = () => {
  const [widgetType, setWidgetType] = useState<WidgetLabelTypes | null>(null)
  const [previewSrc, setPreviewSrc] = useState<string | null>(null)

  const widgetCodeSnippet =
    widgetType && previewSrc
      ? getWidgetCodeSnippet({widgetType, srcLink: previewSrc})
      : null

  return (
    <Wrapper container spacing="s000" direction="row" wrap="nowrap">
      <FormSection>
        <LogoSection />
        <GeneratorForm
          setWidgetType={setWidgetType}
          setPreviewSrc={setPreviewSrc}
        />
      </FormSection>
      <PreviewSection>
        <CodeSnippet widgetCodeSnippet={widgetCodeSnippet} />
        <WidgetPreview widgetType={widgetType} srcLink={previewSrc} />
      </PreviewSection>
    </Wrapper>
  )
}

export default WidgetsGenerator
