import React from 'react'
import {useIntl} from 'react-intl'
import {Field, useFormikContext} from 'formik'
import {WidgetLabelTypes} from 'utils/label'
import {WidgetGeneratorFormValues} from 'widgetsGenerator/GeneratorForm'

import {InputWrapperFormik} from '@daedalus/atlas/helpers/InputWrapperFormik'
import {InputText} from '@daedalus/atlas/InputText'
import {Label} from '@daedalus/atlas/Label'

export const TitleInput = () => {
  const {
    values: {widgetType}
  } = useFormikContext<WidgetGeneratorFormValues>()
  const intl = useIntl()
  const labelValue = intl.formatMessage({
    id: 'widgetGenerator.titleLabel',
    defaultMessage: 'Widget title'
  })
  const placeholder = intl.formatMessage({
    id: 'widgetGenerator.titlePlaceholder',
    defaultMessage: 'Optional'
  })

  return widgetType === WidgetLabelTypes.multipleHotels ? (
    <Label value={labelValue} variant="bodyS">
      <InputWrapperFormik Field={Field} name="title">
        <InputText
          placeholder={placeholder}
          name="title"
          id="title"
          size="md"
        />
      </InputWrapperFormik>
    </Label>
  ) : null
}
