import {map} from 'ramda'

import {SearchResults} from '@findhotel/sapi'

import {
  OldHotelOfferEntity,
  sapiHotelOfferEntityToHotelOfferEntity
} from '../../../../offer/business/offersMapping'
import {transformFacetsToArray} from '../../../utils'
import {
  type BaseMappingContext,
  createMappingContext,
  isValidResponseSearchParams
} from '../utils'

type Transforms = {
  facets: ReturnType<typeof transformFacetsToArray>
  hotelOfferEntities: Record<string, OldHotelOfferEntity>
}

export type TransformedSearchResults = Omit<SearchResults, keyof Transforms> &
  Transforms

const transformOfferEntities = (
  results: Partial<SearchResults>,
  baseMappingContext: BaseMappingContext
): Transforms['hotelOfferEntities'] => {
  const currentParameters = results.searchParameters

  // This is mostly a type guard, search params should be complete with dates from `onAnchor` onwards
  if (!currentParameters || !isValidResponseSearchParams(currentParameters))
    throw new Error(
      'Searched parameters (checkIn, checkOut, rooms) are required for offer transformations'
    )

  const mappingContext = createMappingContext(
    baseMappingContext,
    currentParameters
  )
  return map(
    offerEntity =>
      sapiHotelOfferEntityToHotelOfferEntity(
        offerEntity,
        mappingContext
      ) as unknown as OldHotelOfferEntity, // TODO (@GriffinSauce): remove undefined check from util
    results.hotelOfferEntities
  )
}

/**
 * Process SAPI response data into the shape we'll actually consume
 * The response data is a partial so transforms should only be applied when relevant
 *
 * @param results - results data from any SAPI.search() callback
 * @param baseMappingContext - mapping options from app/user context
 * @returns transformedResults
 */
export const transformSearchResults = (
  results: Partial<SearchResults>,
  baseMappingContext: BaseMappingContext
): Partial<TransformedSearchResults> => {
  return {
    ...(results as Omit<SearchResults, keyof Transforms>),
    ...(results.facets && {
      facets: transformFacetsToArray(results.facets)
    }),
    ...(results.hotelOfferEntities && {
      hotelOfferEntities: transformOfferEntities(results, baseMappingContext)
    })
  }
}
