import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {OnDestinationChangeParams, SearchLocationType} from 'hooks/searchBox'

import {
  SuggestionItemDataType,
  SuggestionSectionDataType
} from '@daedalus/core/src/autocomplete/types'

import {SuggestionsListElement, SuggestionsListSection} from './styles'
import {SuggestionItem} from './SuggestionItem'

export enum SuggestionsListVariant {
  Overlay = 'overlay',
  Popover = 'popover'
}

export interface Props {
  onChange: (params: OnDestinationChangeParams) => void
  listType?: SuggestionsListVariant
  suggestions: SuggestionSectionDataType[]
}

export const SuggestionsList: React.FC<Props> = ({
  onChange,
  suggestions,
  listType = SuggestionsListVariant.Overlay
}) => {
  const suggestionsData = suggestions
  const dispatch = useDispatch()
  const [selectedSuggestion, setSelectedSuggestion] = useState(-1)

  useEffect(() => {
    setSelectedSuggestion(-1)
  }, [suggestionsData])

  useEffect(() => {
    // As we're using this component in desktop now, add keyboard controls to this component
    // Will make it opt-in for now
    if (listType !== 'popover') {
      return
    }

    const flattenedSuggestions = suggestionsData.reduce<
      SuggestionItemDataType[]
    >((acc, {suggestions}) => acc.concat(suggestions || []), [])

    const keyboardHandler = (e: KeyboardEvent) => {
      if (e.key === 'ArrowUp') {
        setSelectedSuggestion(Math.max(selectedSuggestion - 1, -1))
      }

      if (e.key === 'ArrowDown') {
        setSelectedSuggestion(
          Math.min(selectedSuggestion + 1, flattenedSuggestions.length - 1)
        )
      }

      if (e.key === 'Enter') {
        const index = Math.max(selectedSuggestion, 0)
        handleSuggestionClick(flattenedSuggestions[index])()
      }
    }

    document.addEventListener('keydown', keyboardHandler)

    return () => {
      document.removeEventListener('keydown', keyboardHandler)
    }
  }, [listType, selectedSuggestion, suggestionsData])

  const handleSuggestionClick = useCallback(
    (suggestion: SuggestionItemDataType) => () => {
      const {objectDisplayName, objectID, objectType} = suggestion

      onChange({
        displayValue: objectDisplayName,
        value: objectID,
        type: objectType as SearchLocationType
      })
    },
    [onChange, dispatch]
  )

  return (
    <SuggestionsListElement listType={listType}>
      {suggestionsData &&
        suggestionsData.map(({suggestions, type}, sectionIndex) => {
          const sectionOffset = suggestionsData
            .slice(0, sectionIndex)
            .reduce(
              (acc, {suggestions}) =>
                suggestions ? acc + suggestions.length : acc,
              0
            )

          return (
            <SuggestionsListSection key={type} listType={listType}>
              {suggestions?.map((suggestion, i) => {
                const {
                  objectName,
                  objectParentName,
                  objectPlaceTypeName,
                  checkIn,
                  checkOut,
                  rooms,
                  isUserSearchHistory,
                  objectID
                } = suggestion

                return (
                  <div
                    key={[checkIn, checkOut, rooms, objectID].join()}
                    onClick={handleSuggestionClick(suggestion)}
                    role="presentation"
                  >
                    <SuggestionItem
                      name={objectName}
                      parentName={objectParentName}
                      placeTypeName={objectPlaceTypeName}
                      checkIn={checkIn}
                      checkOut={checkOut}
                      rooms={rooms}
                      isUserSearchHistory={isUserSearchHistory}
                      isFocused={selectedSuggestion === sectionOffset + i}
                    />
                  </div>
                )
              })}
            </SuggestionsListSection>
          )
        })}
    </SuggestionsListElement>
  )
}
