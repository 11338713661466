import {WidgetLabelTypes} from 'utils/label'

export const getWidgetHeight = (
  widgetType: WidgetLabelTypes,
  hideDividers?: boolean
) => {
  if (widgetType === WidgetLabelTypes.hotelCard) {
    return '565px'
  }

  return hideDividers ? '508px' : '526px'
}

export const getWidgetCodeSnippet = ({
  srcLink,
  widgetType
}: {
  srcLink: string
  widgetType: WidgetLabelTypes
}) => `
<iframe src=${srcLink} width="100%" height=${getWidgetHeight(
  widgetType
)} frameBorder="0" ></iframe>`
