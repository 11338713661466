import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Button} from '@daedalus/atlas/Button'
import {Card} from '@daedalus/atlas/Card'
import {Divider} from '@daedalus/atlas/Divider'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

interface Props {
  widgetCodeSnippet: string | null
}

interface CopyButtonProps {
  widgetCodeSnippet: string
}

const Wrapper = styled(Card)(
  ({theme}) =>
    css`
      height: 212px;
      margin-bottom: ${theme.layout.spacing.s500}px;
    `
)

const StyledButton = styled(Button)`
  width: 128px;
`

const TopSection = styled(Grid)(
  ({theme}) =>
    css`
      padding: ${theme.layout.spacing.s400}px ${theme.layout.spacing.s500}px;
    `
)

const BottomSection = styled('div')(
  ({theme}) =>
    css`
      padding: ${theme.layout.spacing.s300}px ${theme.layout.spacing.s500}px;
    `
)

const SnippetText = styled('span')(
  ({theme}) =>
    css`
      color: ${theme.colors.content.neutral.c950}

      font-family: Roboto Mono;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    `
)

const CopyButton = ({widgetCodeSnippet}: CopyButtonProps) => {
  const handleCopyToClipBoard = async () =>
    navigator.clipboard.writeText(widgetCodeSnippet)

  return (
    <StyledButton
      size="md"
      isRounded
      iconStart={
        <Icon name="Copy" colorPath="content.neutral.c000" size="sm" />
      }
      onClick={handleCopyToClipBoard}
      fullWidth
    >
      <Text variant="labelS">
        <FormattedMessageWrapper
          id="widgetGenerator.copyButton"
          defaultMessage="Copy"
        />
      </Text>
    </StyledButton>
  )
}

export const CodeSnippet = ({widgetCodeSnippet}: Props) => {
  return widgetCodeSnippet ? (
    <Wrapper borderRadius="md" borderColorPath="border.neutral.c100">
      <TopSection
        container
        direction="row"
        spacing="s000"
        justify="space-between"
        alignItems="center"
      >
        <Text variant="labelM">
          <FormattedMessageWrapper
            id="widgetGenerator.codeSnippetTitle"
            defaultMessage="Code snippet"
          />
        </Text>
        <CopyButton widgetCodeSnippet={widgetCodeSnippet} />
      </TopSection>
      <Divider />
      <BottomSection>
        <SnippetText>{widgetCodeSnippet}</SnippetText>
      </BottomSection>
    </Wrapper>
  ) : null
}
