import React, {useState} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {WidgetLabelTypes} from 'utils/label'

import {Card} from '@daedalus/atlas/Card'
import {Divider} from '@daedalus/atlas/Divider'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {SegmentedControl} from '@daedalus/atlas/SegmentedControl'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {getWidgetHeight} from './utils'

interface Props {
  widgetType: WidgetLabelTypes | null
  srcLink: string | null
}

const Wrapper = styled(Card)(
  ({theme}) =>
    css`
      margin-bottom: ${theme.layout.spacing.s500}px;
    `
)

const TopSection = styled(Grid)(
  ({theme}) =>
    css`
      padding: ${theme.layout.spacing.s400}px ${theme.layout.spacing.s500}px;
    `
)

const BottomSection = styled(Grid)(
  ({theme}) =>
    css`
      padding: ${theme.layout.spacing.s500}px;
    `
)

const PreviewWrapper = styled('div')<{isMobile?: boolean}>(
  ({isMobile}) =>
    css`
      width: ${isMobile ? '360px' : '100%'};
    `
)

export const WidgetPreview = ({srcLink, widgetType}: Props) => {
  const [widgetLayout, setWidgetLayout] = useState('desktop')

  if (!widgetType || !srcLink) return null

  return srcLink ? (
    <Wrapper borderRadius="md" borderColorPath="border.neutral.c100">
      <TopSection
        container
        direction="row"
        spacing="s000"
        justify="space-between"
        alignItems="center"
      >
        <Text variant="labelM">
          <FormattedMessageWrapper
            id="widgetGenerator.widgetPreviewTitle"
            defaultMessage="Widget preview"
          />
        </Text>
        <SegmentedControl
          name="widgetLayout"
          value={widgetLayout}
          onChange={setWidgetLayout}
        >
          <SegmentedControl.Item value={'desktop'}>
            <FormattedMessageWrapper
              id="widgetGenerator.desktopLayout"
              defaultMessage="Desktop"
            />
          </SegmentedControl.Item>
          <SegmentedControl.Item value={'mobile'}>
            <FormattedMessageWrapper
              id="widgetGenerator.mobileLayout"
              defaultMessage="Mobile"
            />
          </SegmentedControl.Item>
        </SegmentedControl>
      </TopSection>
      <Divider />
      <BottomSection
        container
        spacing="s000"
        alignItems="center"
        direction="column"
      >
        <PreviewWrapper isMobile={widgetLayout === 'mobile'}>
          <iframe
            src={srcLink}
            width="100%"
            height={getWidgetHeight(widgetType)}
            frameBorder="0"
            title="preview"
          ></iframe>
        </PreviewWrapper>
      </BottomSection>
    </Wrapper>
  ) : null
}
