import React, {ReactElement, useState} from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {toggle} from 'opticks'
import {SizesType} from 'types/Sizes'
import {ColorPath} from 'types/Theme'

import {cssTheme} from '../../../themes'
import {Divider} from '../Divider'
import {NoStyleButton} from '../helpers/NoStyleButton'
import {Icon} from '../Icon'

type AccordionSize = Extract<SizesType, 'sm' | 'lg' | 'xl'>
interface AccordionProps {
  /** The content rendered inside the open Accordion */
  children: React.ReactNode
  /** Pass through className to allow styles overrides */
  className?: string
  /** The header or title that is always on display */
  header?: ReactElement | string
  /** Whether the accordion will start collapsed or not */
  isOpenByDefault?: boolean
  /** Callback invoked whenever the collapsed state changes */
  onToggle?: (state: boolean) => void
  /** Any React component can be used as an icon */
  icon?: React.ReactNode
  /** If set, accordion trigger will be rendered under the collapsible content */
  isBottomAligned?: boolean
  /** The size of the accordion header */
  size?: AccordionSize
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Whether to show divider on the bottom of Accordion */
  withDivider?: boolean
  /** Change the arrow Icon to chevron */
  chevronIcon?: boolean
  /** Whether to show header side padding*/
  hasSidePadding?: boolean
}

const TabElement = styled.div`
  display: flex;

  flex-direction: column;
  &.--bottom-aligned {
    flex-direction: column-reverse;
  }

  padding: 0 0;
  &.--sidePadding {
    padding: 0 ${cssTheme.layout.spacing.s600};
  }
`

export const Header = styled(NoStyleButton)`
  display: flex;
  text-align: left;
  align-items: center;
  margin: 0;
  cursor: pointer;
  gap: ${cssTheme.layout.spacing.s300};
  border-radius: ${cssTheme.layout.radius.lg};
  &:focus-visible {
    outline: 2px solid ${cssTheme.colors.border.interactive.c950};
  }

  &.--size-sm {
    padding: ${cssTheme.layout.spacing.s300} 0;
    align-self: flex-start;
  }

  &.--size-lg {
    padding: ${cssTheme.layout.spacing.s500} 0;
  }

  &.--size-xl {
    padding: ${cssTheme.layout.spacing.s600} 0;
  }
`

export const Content = styled.div`
  display: block;
  ${cssTheme.typography.text.bodyM}
  &.--hidden {
    display: none;
  }
`

export const HeaderText = styled.span`
  &.--size-sm {
    ${cssTheme.typography.text.bodyS}
  }
  &.--size-lg,
  &.--size-xl {
    ${cssTheme.typography.text.labelM}
    flex-grow: 1;
  }
`

export const ArrowElementWrapper = styled.div`
  transition: transform 0.2s ease 0s;
  transform: rotateZ(180deg);
  color: ${cssTheme.colors?.content.interactive.c500};

  &.--open {
    transform: rotateZ(0);
  }
`

export const Accordion = ({
  header = '',
  isOpenByDefault = false,
  children,
  className,
  onToggle,
  isBottomAligned = false,
  size = 'lg',
  icon,
  dataId,
  withDivider = false,
  chevronIcon = false,
  hasSidePadding = false
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault)

  const handleOnOpen = () => {
    setIsOpen(!isOpen)
    if (onToggle) onToggle(!isOpen)
  }

  return (
    <>
      <TabElement
        data-id={dataId}
        className={cx(
          className,
          isBottomAligned && '--bottom-aligned',
          hasSidePadding && '--sidePadding'
        )}
      >
        <Header
          className={`--size-${size}`}
          onClick={handleOnOpen}
          type="button"
        >
          {icon && <div>{icon}</div>}
          <HeaderText className={`--size-${size}`}>{header}</HeaderText>
          <ArrowElementWrapper className={cx(isOpen && '--open')}>
            {chevronIcon ? (
              <Icon
                name="ChevronUp"
                colorPath="content.neutral.c600"
                size="md"
              />
            ) : (
              <Icon
                name="CaratUp"
                size="sm"
                colorPath={toggle<ColorPath[]>(
                  '4728f379-vio-color-restyle',
                  'content.interactive.c500',
                  'content.interactive.c800'
                )}
              />
            )}
          </ArrowElementWrapper>
        </Header>
        <Content className={cx(!isOpen && '--hidden')}>{children}</Content>
      </TabElement>
      {withDivider && <Divider />}
    </>
  )
}
