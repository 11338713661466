import React from 'react'
import styled from '@emotion/styled'
import {useFormikContext} from 'formik'
import {GuestPickerPopover} from 'widgets/HotelCardWidget/GuestPickerPopover'
import {WidgetGeneratorFormValues} from 'widgetsGenerator/GeneratorForm'

import {
  addIdsToRoomsSplit,
  numberOfGuests,
  roomsSplitToString,
  roomsToConfigurationObject
} from '@daedalus/core/src/room/business/roomConfiguration'
import {UniqueRoomSplitType} from '@daedalus/core/src/room/types/RoomConfiguration'

const Container = styled('div')`
  width: 140px;
`

export const GuestsPicker = () => {
  const {
    values: {rooms},
    setFieldValue
  } = useFormikContext<WidgetGeneratorFormValues>()

  const {roomsSplit} = roomsToConfigurationObject(rooms)
  const guests = addIdsToRoomsSplit(roomsSplit)
  const totalGuests = numberOfGuests(rooms)

  const onGuestsUpdate = (rooms: UniqueRoomSplitType[]) => {
    const newRooms = roomsSplitToString(rooms)
    setFieldValue('rooms', newRooms)
  }

  return (
    <Container>
      <GuestPickerPopover
        guests={guests}
        totalValueOfGuests={totalGuests}
        onSubmit={onGuestsUpdate}
      />
    </Container>
  )
}
