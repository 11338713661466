import React from 'react'
import {useFormikContext} from 'formik'

import {Button} from '@daedalus/atlas/Button'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {WidgetGeneratorFormValues} from './GeneratorForm'

interface Props {
  setSearchNames: (newArr: {id: number; name: string}[]) => void
  setPreviewSrc: (src: string) => void
}

export const ClearFormButton = ({setSearchNames, setPreviewSrc}: Props) => {
  const {resetForm} = useFormikContext<WidgetGeneratorFormValues>()

  const onClick = () => {
    setPreviewSrc('')
    setSearchNames([])
    resetForm()
  }

  return (
    <Button type="button" onClick={onClick} size="xl" variant="quiet" fullWidth>
      <FormattedMessageWrapper
        id="widgetGenerator.clearForm"
        defaultMessage="Clear form"
      />
    </Button>
  )
}
