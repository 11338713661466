import React from 'react'
import {useIntl} from 'react-intl'
import {Field, useFormikContext} from 'formik'
import {WidgetGeneratorFormValues} from 'widgetsGenerator/GeneratorForm'

import {InputWrapperFormik} from '@daedalus/atlas/helpers/InputWrapperFormik'
import {InputSelect} from '@daedalus/atlas/InputSelect'
import ALL_LANGUAGES from '@daedalus/core/src/localization/services/locale'

export const LanguageSelector = () => {
  const context = useFormikContext<WidgetGeneratorFormValues>()

  const intl = useIntl()

  const placeholder = intl.formatMessage({
    id: 'widgetGenerator.languagePlaceholder',
    defaultMessage: 'Language'
  })

  const onChange = (value: string) => {
    context.setFieldValue('language', value)
  }

  return (
    <InputWrapperFormik Field={Field} name="language">
      <InputSelect
        placeholder={placeholder}
        name="widgetLanguage"
        id="widgetLanguage"
        onChange={onChange}
        value={context.values.language}
        size="md"
      >
        {ALL_LANGUAGES.map(language => (
          <option key={language.code} value={language.code}>
            {language.displayName}
          </option>
        ))}
      </InputSelect>
    </InputWrapperFormik>
  )
}
