export enum WidgetLabelTypes {
  hotelCard = 'hotelCard',
  multipleHotels = 'multipleHotels',
  multipleHotelsWithSearch = 'multipleHotelsWithSearch',
  multipleHotelsWithBanner = 'multipleHotelsWithBanner'
}

export const getPapiLabel = (
  widgetType: WidgetLabelTypes,
  affiliateLabel?: string
) =>
  affiliateLabel
    ? `lbl=${encodeURIComponent(affiliateLabel)}&wt=${widgetType}`
    : `wt=${widgetType}`
