import React from 'react'

import {Button} from '@daedalus/atlas/Button'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

export const GenerateButton = () => {
  return (
    <Button type="submit" size="xl" fullWidth>
      <FormattedMessageWrapper
        id="widgetGenerator.generate"
        defaultMessage="Generate"
      />
    </Button>
  )
}
