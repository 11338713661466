import * as React from 'react'
import {FormattedDate} from 'react-intl'

import {Icon, iconsList} from '@daedalus/atlas/Icon'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {roomsToConfigurationObject} from '@daedalus/core/src/room/business/roomConfiguration'
import {dateStringToMiddayDate} from '@daedalus/core/src/utils/date'
import {guestPickerMessages} from '@daedalus/shared/src/search/GuestPicker/guestPickerMessages'

import {
  IconWrapper,
  SuggestionElement,
  SuggestionTextElement,
  SuggestionTextElementDetail,
  SuggestionTextHistory,
  SuggestionTextWrapper,
  SuggestionTypeLabel
} from './styles'

type PropsType = {
  checkIn?: string
  checkOut?: string
  isUserSearchHistory: boolean
  name: string
  parentName: string
  placeTypeName: string | null | undefined
  rooms?: string
  isFocused?: boolean
}

const TYPE_TO_ICON_MAP: Record<string, keyof typeof iconsList> = {
  history: 'Clock',
  area: 'Pin',
  property: 'Building',
  airport: 'PlaneTakeOff',
  station: 'Bus',
  city: 'Buildings',
  country: 'Pin'
} as const

const getIcon = (type: keyof typeof TYPE_TO_ICON_MAP | null | undefined) => {
  return type ? <Icon name={TYPE_TO_ICON_MAP[type]} size="md" /> : null
}

const renderShortFormattedDate = (value: string): React.ReactNode => (
  <FormattedDate
    value={dateStringToMiddayDate(value)}
    month="short"
    day="2-digit"
  />
)

export const SuggestionItem: React.FC<PropsType> = ({
  isUserSearchHistory,
  isFocused,
  name,
  parentName,
  placeTypeName,
  checkIn = '',
  checkOut = '',
  rooms = ''
}) => {
  if (!placeTypeName) return null

  const placeTypeFormattedTextProps = {
    id: placeTypeName,
    defaultMessage: placeTypeName
  }

  const renderSuggestion = () => {
    return (
      <SuggestionElement
        data-id="DestinationSuggestion--place"
        isFocused={isFocused}
      >
        <IconWrapper>{getIcon(placeTypeName)}</IconWrapper>
        <SuggestionTextWrapper>
          <SuggestionTextElement>
            <span dangerouslySetInnerHTML={{__html: name}} />
            {placeTypeName && (
              <SuggestionTypeLabel>
                <FormattedMessageWrapper {...placeTypeFormattedTextProps} />
              </SuggestionTypeLabel>
            )}
          </SuggestionTextElement>
          <SuggestionTextElementDetail>
            {parentName}
          </SuggestionTextElementDetail>
        </SuggestionTextWrapper>
      </SuggestionElement>
    )
  }

  const renderUserSearchHistory = () => {
    const {numberOfAdults, numberOfChildren, numberOfRooms} =
      roomsToConfigurationObject(rooms)

    const numberOfGuests = numberOfAdults + numberOfChildren

    return (
      <SuggestionElement
        data-id="DestinationSuggestion--history"
        isFocused={isFocused}
      >
        <IconWrapper>{getIcon('history')}</IconWrapper>
        <SuggestionTextWrapper>
          <SuggestionTextElement>
            <SuggestionTextHistory>{name}</SuggestionTextHistory>
          </SuggestionTextElement>
          <SuggestionTextElementDetail>
            {renderShortFormattedDate(checkIn)}
            {` - `}
            {renderShortFormattedDate(checkOut)}
            {` · `}
            {numberOfGuests}{' '}
            <FormattedMessageWrapper
              {...guestPickerMessages.guest}
              values={{count: numberOfGuests}}
            />
            , {numberOfRooms}{' '}
            <FormattedMessageWrapper
              {...guestPickerMessages.room}
              values={{count: numberOfRooms}}
            />
          </SuggestionTextElementDetail>
        </SuggestionTextWrapper>
      </SuggestionElement>
    )
  }

  return isUserSearchHistory ? renderUserSearchHistory() : renderSuggestion()
}
