import React from 'react'
import {useFormikContext} from 'formik'
import {WidgetLabelTypes} from 'utils/label'
import {WidgetGeneratorFormValues} from 'widgetsGenerator/GeneratorForm'

import {SegmentedControl} from '@daedalus/atlas/SegmentedControl'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

interface Props {
  setSearchNames: (
    values: {
      id: number
      name: string
    }[]
  ) => void
  setPreviewSrc: (src: string) => void
}

export const WidgetTypeSelector = ({setSearchNames, setPreviewSrc}: Props) => {
  const context = useFormikContext<WidgetGeneratorFormValues>()

  const onChange = (value: string) => {
    context.setFieldValue('widgetType', value)
    setSearchNames([])
    setPreviewSrc('')
    context.setTouched({}, false)
  }

  return (
    <SegmentedControl
      name="reviewScore"
      value={context.values.widgetType}
      onChange={onChange}
      isFullWidth
    >
      <SegmentedControl.Item value={WidgetLabelTypes.hotelCard}>
        <FormattedMessageWrapper
          id="widgetGenerator.hotelCardWidgetButton"
          defaultMessage="Hotel card widget"
        />
      </SegmentedControl.Item>
      <SegmentedControl.Item value={WidgetLabelTypes.multipleHotels}>
        <FormattedMessageWrapper
          id="widgetGenerator.multipleHotelsWidgetButton"
          defaultMessage="Multiple hotels widget"
        />
      </SegmentedControl.Item>
    </SegmentedControl>
  )
}
