import React from 'react'
import {styled} from '@linaria/react'

import {PdLocked, PdUnlocked} from '@findhotel/atlas-assets'

import {cssTheme} from '../../../../themes'

export interface Props {
  /** Whether the Lock is opened or closed */
  isLocked?: boolean
  /** The size of the lock */
  size?: number
  /** Whether the Lock is white color */
  isWhite?: boolean
  /** Whether the Lock follows the current color (color from the parent element) */
  isCurrentColor?: boolean
}

const WrapperElement = styled.span<{size: number}>`
  font-size: ${({size}) => size}px;
  display: inline-flex;
  align-items: center;
`

// added as part of b4f4f3cc-website-restyle-v3
export const NewStyleLock = ({isLocked = true, size = 16}: Props) => {
  return (
    <WrapperElement size={size}>
      {isLocked ? <PdLocked size={size} /> : <PdUnlocked size={size} />}
    </WrapperElement>
  )
}

export const Lock = ({
  isLocked = true,
  size = 16,
  isWhite = false,
  isCurrentColor = false
}: Props) => {
  const color = isWhite
    ? cssTheme.colors.content.neutral.c000
    : cssTheme.colors.content.special.c500

  return (
    <WrapperElement size={size}>
      {isLocked ? (
        <PdLocked
          size={size}
          color={!isCurrentColor ? color : 'currentColor'}
        />
      ) : (
        <PdUnlocked
          size={size}
          color={!isCurrentColor ? color : 'currentColor'}
        />
      )}
    </WrapperElement>
  )
}
