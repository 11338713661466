import React, {ReactElement} from 'react'
import {styled} from '@linaria/react'

import {cssTheme} from '../../../themes'
import {linariaMq} from '../../../utils/breakpoints'
import {Text} from '../Text'

interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Name of radio button. Programmatically set by parent component SegmentedControl */
  name?: string
  /** Value of current radio button */
  value: string
  /** Checked state of current radio button. Programmatically set by parent component */
  checked?: boolean
  /** An onChange callback passed down by the controlling parent component */
  onChange?: (event: React.ChangeEvent) => void
  /** An icon placed before the item content. It can be any type of React Element */
  icon?: ReactElement
  /** Text content of current item */
  children: string | ReactElement
  /** Whether the SegmentedControl should span the whole available width. Programmatically set by parent component  */
  isFullWidth?: boolean
}

const ITEM_MIN_WIDTH = 62
const DIVIDER_HEIGHT = 22
const DIVIDER_WIDTH = 1
const DIVIDER_TOP = 6

export const ItemBody = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${cssTheme.layout.spacing.s200};
  background: ${cssTheme.colors.segmentedControl.default.background};
  min-width: ${ITEM_MIN_WIDTH}px;
  padding: ${cssTheme.layout.spacing.s250} ${cssTheme.layout.spacing.s400};
  border: 1px solid ${cssTheme.colors.segmentedControl.default.background};
  color: ${cssTheme.colors.segmentedControl.default.content};
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    height: ${DIVIDER_HEIGHT}px;
    width: ${DIVIDER_WIDTH}px;
    top: ${DIVIDER_TOP}px;
    right: 0;
    left: auto;
    background-color: ${cssTheme.colors.segmentedControl.default.divider};
  }
`

const Wrapper = styled.label<{isFullWidth: boolean}>`
  display: ${({isFullWidth}) => (isFullWidth ? 'inline-grid' : 'inline-flex')};
  flex-grow: ${({isFullWidth}) => (isFullWidth ? '1' : '0')};
  align-items: center;
  position: relative;

  &:first-child ${ItemBody} {
    border-radius: ${cssTheme.layout.radius.lg} 0 0 ${cssTheme.layout.radius.lg};
    [dir='rtl'] & {
      border-radius: 0 ${cssTheme.layout.radius.lg} ${cssTheme.layout.radius.lg}
        0;
    }
  }

  &:last-child ${ItemBody} {
    border-radius: 0 ${cssTheme.layout.radius.lg} ${cssTheme.layout.radius.lg} 0;
    [dir='rtl'] & {
      border-radius: ${cssTheme.layout.radius.lg} 0 0
        ${cssTheme.layout.radius.lg};
    }
    &:after {
      display: none;
    }
  }

  ${linariaMq.desktopXs} {
    &:hover ${ItemBody} {
      background-color: ${cssTheme.colors.segmentedControl.default.hover
        .background};
      border-color: ${cssTheme.colors.segmentedControl.default.hover
        .background};
      color: ${cssTheme.colors.segmentedControl.default.hover.content};
    }
  }
`

export const Input = styled.input`
  position: absolute;
  left: -9999px;
  top: 0;
  opacity: 0;

  &:focus-visible + ${ItemBody} {
    outline: 1px solid ${cssTheme.colors.segmentedControl.default.focus.border};
    outline-offset: 1px;
    z-index: 1;
  }

  &:active + ${ItemBody} {
    background-color: ${cssTheme.colors.segmentedControl.default.active
      .background};
    border-color: ${cssTheme.colors.segmentedControl.default.active.background};
    color: ${cssTheme.colors.segmentedControl.default.active.content};

    &:after {
      background-color: ${cssTheme.colors.segmentedControl.default.active
        .background};
    }
  }

  &:checked + ${ItemBody} {
    background-color: ${cssTheme.colors.segmentedControl.checked.background};
    border-color: ${cssTheme.colors.segmentedControl.checked.background};
    color: ${cssTheme.colors.segmentedControl.checked.content};

    &:after {
      background-color: ${cssTheme.colors.segmentedControl.checked.background};
    }
  }
`

export const SegmentedControlItem = ({
  icon,
  name,
  value,
  checked,
  onChange,
  isFullWidth = false,
  children,
  className
}: Props) => {
  return (
    <Wrapper className={className} isFullWidth={isFullWidth}>
      <Input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <ItemBody>
        {icon && icon}
        <Text variant="labelS" as="span">
          {children}
        </Text>
      </ItemBody>
    </Wrapper>
  )
}
