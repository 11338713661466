import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import OutsideClickHandler from 'react-outside-click-handler'
import {useSelector} from 'react-redux'
import {useTheme} from '@emotion/react'
import styled from '@emotion/styled'
import {Field, FieldValidator} from 'formik'
import {OnDestinationChangeParams} from 'hooks/searchBox'

import {InputWrapperFormik} from '@daedalus/atlas/helpers/InputWrapperFormik'
import {Icon} from '@daedalus/atlas/Icon'
import {InputText} from '@daedalus/atlas/InputText'
import {Popover, PopoverPlacement} from '@daedalus/atlas/Popover'
import {getIsBrandKiwi} from '@daedalus/core/src/_web/brand/modules/selectors'
import {SuggestionSectionDataType} from '@daedalus/core/src/autocomplete/types'

import {IconWrapper, searchInputBaseStyles} from './styles'
import {SuggestionsList, SuggestionsListVariant} from './SuggestionsList'

interface Props {
  value: string
  isRtl: boolean
  inputSize?: 'md' | 'lg'
  withFormik?: boolean
  searchValue: string
  suggestions:
    | {
        suggestions: SuggestionSectionDataType[]
        suggestionsSearchValue: string
      }
    | Record<string, undefined>
  validate?: FieldValidator
  onChange: (value: OnDestinationChangeParams) => void
  onInputClick?: () => void
  onSuggestionsRequested: (value: string) => Promise<void>
  showPickers?: boolean
}

const CONTENT_WIDTH = 360
export const POPOVER_VERTICAL_OFFSET = 74
export const POPOVER_HORIZONTAL_OFFSET = 2

const PopoverContent = styled('div')`
  width: ${CONTENT_WIDTH}px;
`

const StyledInput = styled(InputText)<{
  isFocused?: boolean
  borderRadius?: number
}>(({theme, borderRadius}) => searchInputBaseStyles({theme, borderRadius}))

const InputWrapper = ({
  children,
  withFormik,
  validate
}: {
  children: React.ReactElement<typeof InputText>
  withFormik?: boolean
  validate?: FieldValidator
}) =>
  withFormik ? (
    <InputWrapperFormik Field={Field} name="destination" validate={validate}>
      {children}
    </InputWrapperFormik>
  ) : (
    <>{children}</>
  )

export const DestinationPickerPopover = ({
  value,
  inputSize = 'md',
  withFormik,
  isRtl,
  searchValue,
  suggestions,
  validate,
  onChange,
  onInputClick,
  onSuggestionsRequested,
  showPickers
}: Props) => {
  const {formatMessage} = useIntl()
  const brandIsKiwi = useSelector(getIsBrandKiwi)
  const theme = useTheme()

  const containerRef = useRef<HTMLElement>(null)
  const [destinationPickerOpen, setDestinationPickerOpen] =
    useState<boolean>(false)
  const [suggestionSelected, setSuggestionSelected] = useState<string>('')

  const [isDestinationPickerActive, setIsDestinationPickerActive] =
    useState(false)

  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    if (value && inputValue.length === 0) {
      setInputValue(value)
    }
  }, [value, inputValue])

  const handleSetOpenDatePicker = useCallback(
    (destinationPickerOpen: boolean) => {
      if (showPickers) {
        setDestinationPickerOpen(destinationPickerOpen)
      }
    },
    [showPickers]
  )

  const onSuggestionClick = (suggestion: OnDestinationChangeParams) => {
    setSuggestionSelected(suggestion.displayValue)
    setInputValue(suggestion.displayValue)
    onChange(suggestion)
    handleSetOpenDatePicker(false)
    setIsDestinationPickerActive(false)
  }

  const onInputFocus = () => {
    setIsDestinationPickerActive(true)
    onInputClick && onInputClick()
  }

  const onInputBlur = () => {
    setIsDestinationPickerActive(false)
  }

  const onPopoverOutsideClick = () => {
    handleSetOpenDatePicker(false)
  }

  const onOutsideDestinationPickerClick = () => {
    setInputValue(value)
  }

  useEffect(() => {
    if (inputValue.length > 2 || (withFormik && value.length > 2)) {
      if (
        (inputValue !== searchValue && inputValue !== suggestionSelected) ||
        (withFormik && value !== searchValue && value !== suggestionSelected)
      ) {
        setSuggestionSelected('')
        if (withFormik) {
          onSuggestionsRequested(value)
        } else {
          onSuggestionsRequested(inputValue)
        }
      }
      if (suggestions.suggestions && isDestinationPickerActive) {
        handleSetOpenDatePicker(true)
      }
    } else {
      handleSetOpenDatePicker(false)
    }
  }, [
    withFormik,
    value,
    searchValue,
    inputValue,
    onSuggestionsRequested,
    suggestionSelected,
    suggestions.suggestions,
    isDestinationPickerActive,
    handleSetOpenDatePicker
  ])

  return (
    <OutsideClickHandler onOutsideClick={onOutsideDestinationPickerClick}>
      <InputWrapper withFormik={withFormik} validate={validate}>
        <StyledInput
          ref={containerRef as React.RefObject<HTMLInputElement>}
          id="destination"
          name="destination"
          placeholder={formatMessage({
            id: 'search',
            defaultMessage: 'Search'
          })}
          size={inputSize}
          onChange={setInputValue}
          value={inputValue}
          isFocused={isDestinationPickerActive}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          icon={
            <IconWrapper>
              <Icon name="Pin" size="md" />
            </IconWrapper>
          }
          borderRadius={brandIsKiwi ? theme.layout.radius.sm : undefined}
        ></StyledInput>
      </InputWrapper>

      <Popover
        isOpen={destinationPickerOpen}
        anchorRef={containerRef}
        verticalOffset={POPOVER_VERTICAL_OFFSET}
        horizontalOffset={POPOVER_HORIZONTAL_OFFSET}
        onOutsideClick={onPopoverOutsideClick} //close it when click outside
        placement={isRtl ? PopoverPlacement.Right : PopoverPlacement.Left}
      >
        <PopoverContent>
          <SuggestionsList
            listType={SuggestionsListVariant.Popover}
            onChange={onSuggestionClick}
            suggestions={suggestions.suggestions || []}
          />
        </PopoverContent>
      </Popover>
    </OutsideClickHandler>
  )
}
