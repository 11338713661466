import React, {useState} from 'react'
import styled from '@emotion/styled'
import {useFormikContext} from 'formik'
import {DatePicker as WidgetDatePicker} from 'widgets/components/DatePicker'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {DatePickerType} from '@daedalus/core/src/datePicker/types'

const Container = styled('div')`
  width: 286px;
`

export const DatePicker = () => {
  const {isMobile} = useDeviceLayout()

  const [checkIn, setCheckIn] = useState('')
  const [checkOut, setCheckOut] = useState('')

  const context = useFormikContext()

  const [openedDatePickerType, setOpenedDatePickerType] =
    useState<DatePickerType | null>(null)

  const onDatesUpdate = (checkIn: string, checkOut: string) => {
    setCheckIn(checkIn)
    setCheckOut(checkOut)
    context.setFieldValue('checkIn', checkIn)
    context.setFieldValue('checkOut', checkOut)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onDatesSubmit = () => {}

  return (
    <Container>
      <WidgetDatePicker
        isOverlay={isMobile}
        checkIn={checkIn}
        checkOut={checkOut}
        openedDatePickerType={openedDatePickerType}
        onSetOpenedDatePickerType={setOpenedDatePickerType}
        onUpdate={onDatesUpdate}
        onSubmit={onDatesSubmit}
      />
    </Container>
  )
}
