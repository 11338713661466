import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Text} from '@daedalus/atlas/Text'
import {brands} from '@daedalus/core/src/brand/config'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {BrandLogo} from '@daedalus/shared/src/whiteLabel/BrandLogo'

const Wrapper = styled(Grid)(
  ({theme}) => css`
    margin-bottom: ${theme.layout.spacing.s600}px;
  `
)
const LogoWrapper = styled(Grid)`
  max-width: 116px;
`

export const LogoSection = () => {
  return (
    <Wrapper
      container
      direction="row"
      spacing="s000"
      alignItems="flex-end"
      gap="s300"
    >
      <LogoWrapper container spacing="s000" alignItems="center">
        <BrandLogo brand={brands.vio} />
      </LogoWrapper>
      <Text as="span" variant="labelS" colorPath="content.neutral.c700">
        <FormattedMessageWrapper
          id="widgetsGenerator.formTitle"
          defaultMessage="Widgets generator"
        />
      </Text>
    </Wrapper>
  )
}
