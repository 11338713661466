import React from 'react'
import {useIntl} from 'react-intl'
import {Field, useFormikContext} from 'formik'
import {WidgetGeneratorFormValues} from 'widgetsGenerator/GeneratorForm'

import {InputWrapperFormik} from '@daedalus/atlas/helpers/InputWrapperFormik'
import {InputSelect} from '@daedalus/atlas/InputSelect'
import {availableCountries} from '@daedalus/core/src/localization/business/countries'

export const CountrySelector = () => {
  const intl = useIntl()

  const context = useFormikContext<WidgetGeneratorFormValues>()
  const {
    values: {placeId, placeName}
  } = context

  if (placeId || placeName) {
    return null
  }

  const placeholder = intl.formatMessage({
    id: 'widgetGenerator.countryPlaceholder',
    defaultMessage: 'Country'
  })

  const onChange = (value: string) => {
    context.setFieldValue('currency', value)
  }

  return (
    <InputWrapperFormik Field={Field} name="country">
      <InputSelect
        size="md"
        placeholder={placeholder}
        name="country"
        id="country"
        onChange={onChange}
        value={context.values.currency}
        setFieldValue={onChange}
      >
        {Object.values(availableCountries).map(({name}) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </InputSelect>
    </InputWrapperFormik>
  )
}
