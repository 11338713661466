import React from 'react'
import {useIntl} from 'react-intl'
import {Field, useFormikContext} from 'formik'
import Settings from 'Settings'
import {CurrencyType} from 'types/Currency'
import {WidgetGeneratorFormValues} from 'widgetsGenerator/GeneratorForm'

import {InputWrapperFormik} from '@daedalus/atlas/helpers/InputWrapperFormik'
import {InputSelect} from '@daedalus/atlas/InputSelect'
import {
  getEnabledCurrencies,
  splitPopularAndOtherCurrencies
} from '@daedalus/core/src/localization/business/currency'

const Option = ({currency}: {currency: CurrencyType}) => (
  <option key={currency.code} value={currency.code}>
    {currency.code}
  </option>
)

export const CurrencySelector = () => {
  const context = useFormikContext<WidgetGeneratorFormValues>()
  const intl = useIntl()

  const onChange = (value: string) => {
    context.setFieldValue('currency', value)
  }

  const enabledCurrencies = getEnabledCurrencies(
    Settings.get('REACT_APP_ENABLED_CURRENCIES')
  )

  const {currenciesList, popularCurrenciesList} =
    splitPopularAndOtherCurrencies(enabledCurrencies)

  const placeholder = intl.formatMessage({
    id: 'widgetGenerator.currencyPlaceholder',
    defaultMessage: 'Currency'
  })

  return (
    <InputWrapperFormik Field={Field} name="currency">
      <InputSelect
        size="md"
        placeholder={placeholder}
        name="currency"
        id="currency"
        onChange={onChange}
        value={context.values.currency}
        setFieldValue={onChange}
      >
        <optgroup>
          {popularCurrenciesList.map(currency => (
            <Option key={currency.code} currency={currency} />
          ))}
        </optgroup>
        <optgroup label="-----------">
          {currenciesList.map(currency => (
            <Option key={currency.code} currency={currency} />
          ))}
        </optgroup>
      </InputSelect>
    </InputWrapperFormik>
  )
}
