import React from 'react'
import {Route, Switch} from 'react-router-dom'
import MainPage from 'views/MainPage'
import WidgetsGenerator from 'views/WidgetsGenerator'

export const MAIN_PAGE_PATH = '/'
export const WIDGET_GENERATOR_PATH = '/widgets/generator'

const Routes = () => (
  <Switch>
    <Route component={WidgetsGenerator} path={WIDGET_GENERATOR_PATH} />
    <Route component={MainPage} path={MAIN_PAGE_PATH} />
  </Switch>
)

export default Routes
