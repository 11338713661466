import Settings from 'Settings'

import {BrandCode} from '@daedalus/core/src/brand/types'
import {SapiEffectParams} from '@daedalus/core/src/sapi/services/useInitializeSapi'
import {DeviceType, ProfileKey} from '@findhotel/sapi'

export interface SapiInitParams {
  language?: string
  currency?: string
  countryCode?: string
  deviceType?: DeviceType
  brand?: BrandCode
}

export const useGetSapiInitializationParams = ({
  language = 'en',
  currency = 'USD',
  countryCode = 'US',
  deviceType = 'desktop',
  brand = 'vio'
}: SapiInitParams): SapiEffectParams => {
  const profileKey = Settings.get('REACT_APP_SAPI_PROFILE_KEY') as ProfileKey
  return {
    language,
    currency,
    countryCode,
    deviceType,
    brand,
    anonymousId: 'widgets',
    profileKey: profileKey
  }
}
