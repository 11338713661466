import {useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {SapiInitParams, useGetSapiInitializationParams} from 'services/sapi'

import {SuggestionSectionDataType} from '@daedalus/core/src/autocomplete/types'
import {getSapiClient} from '@daedalus/core/src/sapi/services'
import {useInitializeSapi} from '@daedalus/core/src/sapi/services/useInitializeSapi'
import {Suggestion, SuggestType} from '@findhotel/sapi'

export type SearchLocationType = 'hotel' | 'place' | 'area'

export type OnDestinationChangeParams = {
  displayValue: string
  type: Omit<SearchLocationType, 'area'>
  value: string
}

export const getDestinationParameter = (
  type: Omit<SearchLocationType, 'area'>,
  value: string | null | undefined
): Record<string, unknown> => {
  switch (type) {
    case 'hotel': {
      return {hotelId: value}
    }

    case 'place': {
      return {placeId: value}
    }

    default: {
      return {}
    }
  }
}

export const buildSapiSuggestions = (
  response: Suggestion[],
  hideApiSuggestionsForEmptySearch: boolean
): SuggestionSectionDataType[] => {
  const suggestions: SuggestionSectionDataType[] = []

  if (!hideApiSuggestionsForEmptySearch) {
    if (response?.length > 0) {
      // eslint-disable-next-line fp/no-mutating-methods
      suggestions.push({
        type: 'apiSuggestions',
        suggestions: response.map(item => {
          return {
            isUserSearchHistory: false,
            objectID: item.objectID,
            objectName: item.highlightValue,
            objectDisplayName: item.value,
            objectParentName: item.placeDisplayName,
            objectPlaceTypeName: item.placeTypeName,
            objectType: item.objectType,
            placeType: 23
          }
        })
      })
    }
  }

  return suggestions
}

/**
 * @returns A set of utils and values for managing the destination picker
 */
export const useSearchBoxDestinationPicker = (
  sapiInitParams: SapiInitParams,
  suggestsTypes: SuggestType[] = []
) => {
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState<string>('')
  const [suggestions, setSuggestions] = useState<
    | {
        suggestions: SuggestionSectionDataType[]
        suggestionsSearchValue: string
      }
    | Record<string, undefined>
  >({})
  const [parameters, setParameters] = useState<Record<string, unknown>>({})
  const [destinationHasError, setDestinationHasError] = useState<boolean>(false)
  const [destinationErrorMessage, setDestinationErrorMessage] =
    useState<string>('')

  useInitializeSapi(useGetSapiInitializationParams(sapiInitParams || {}))

  /**
   * Creates a new destination search params object from the passed in params and clears the destination error message, if necessary.
   * Updates the search box values with the destination search params object. Also updates the destination display value and search box path.
   * @param OnDestinationChangeParams -
   */
  const handleDestinationChange = useCallback(
    ({displayValue, type, value}: OnDestinationChangeParams): void => {
      const parameters = {
        placeId: undefined,
        hotelId: undefined,
        boundingBox: undefined,
        userLocationSearch: undefined,
        ...getDestinationParameter(type, value)
      }

      if (
        destinationHasError &&
        (parameters.placeId || parameters.hotelId || displayValue)
      ) {
        setDestinationHasError(false)
        setDestinationErrorMessage('')
      }

      dispatch(setParameters(parameters))
    },
    [destinationHasError, dispatch]
  )

  /**
   * Makes a new SAPI request for suggestions based on the passed in value and maps the response to match our requirements.
   * Dispatches a setSuggestions event with the mapped response.
   * @param value -
   * @param shouldLoadUserSearchHistory -
   */
  const handleSuggestionsRequested = async (value: string) => {
    if (value !== searchValue) {
      setSearchValue(value)
      const sapiClient = await getSapiClient()

      const data = await sapiClient.suggest(value, undefined, suggestsTypes)
      if (data) {
        const mappedResponse = buildSapiSuggestions(data, !value)
        setSuggestions({
          suggestions: mappedResponse,
          suggestionsSearchValue: value
        })
      }
    }
  }

  return {
    onDestinationChange: handleDestinationChange,
    destinationHasError,
    destinationErrorMessage,
    setDestinationErrorMessage,
    setDestinationHasError,
    onSuggestionsRequested: handleSuggestionsRequested,
    suggestions,
    parameters,
    searchValue
  }
}
