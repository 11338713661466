import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

import {StartIconsWrapper} from '@daedalus/atlas/helpers/InputBase'
import {TextInput} from '@daedalus/atlas/InputText'
import {getLinariaClassName} from '@daedalus/atlas/utils/getLinariaClassName'

type ListType = 'overlay' | 'popover'

/**
 * Suggestions list
 */
export const SuggestionsListElement = styled('div')<{
  listType: ListType
}>(
  ({theme, listType}) => css`
    height: 100%;

    ${listType === 'overlay'
      ? `
        overflow: scroll;
        padding-top: ${theme.layout.spacing.s400}px;
      `
      : `
        padding: ${theme.layout.spacing.s500}px 0 ${theme.layout.spacing.s400}px;
      `}
  `
)

export const SuggestionsListSection = styled('div')<{listType: ListType}>(
  ({theme, listType}: {theme: ThemeType; listType: ListType}) => css`
    padding: ${theme.layout.spacing.s300}px 0;
    ${listType === 'popover' && `padding: 0;`}
  `
)

/**
 * Suggestions item
 */
export const SuggestionElement = styled('div')<{
  isFocused?: boolean
}>(
  ({theme, isFocused}: {theme: ThemeType; isFocused?: boolean}) => css`
    display: flex;
    color: ${theme.colors.content.neutral.c950};
    ${theme.typography.text.bodyS}
    position: relative;
    padding: ${theme.layout.spacing.s400}px ${theme.layout.spacing.s500}px;
    cursor: pointer;
    :hover {
      background-color: ${theme.colors.background.neutral.c050};
    }
    ${isFocused &&
    css`
      background-color: ${theme.colors.background.neutral.c050};
    `}
  `
)

export const SuggestionTextWrapper = styled('div')`
  flex: 2;
`

export const SuggestionTextElement = styled('div')(
  ({theme}: {theme: ThemeType}) => css`
    display: flex;
    justify-content: space-between;
    ${theme.typography.text.bodyS}

    em {
      font-style: normal;
      ${theme.typography.text.labelS}
    }
  `
)

export const SuggestionTextHistory = styled('div')(
  ({theme}: {theme: ThemeType}) => css`
    ${theme.typography.text.labelS}
  `
)

export const SuggestionTextElementDetail = styled('div')(
  ({theme}: {theme: ThemeType}) => css`
    color: ${theme.colors.content.neutral.c600};
  `
)

export const SuggestionTypeLabel = styled('span')(
  ({theme}: {theme: ThemeType}) => css`
    color: ${theme.colors.content.neutral.c600};
    ${theme.typography.text.labelS}
    padding-left: ${theme.layout.spacing.s250}px;
  `
)

export const IconWrapper = styled('div')``

export const searchInputBaseStyles = ({
  theme,
  borderRadius
}: {
  theme: ThemeType
  borderRadius?: number
}) => css`
  ${getLinariaClassName(TextInput)} {
    border-radius: ${borderRadius ? borderRadius : theme.layout.radius.lg}px;
    background: ${theme.colors.background.neutral.c000} !important;

    :focus {
      ~ ${getLinariaClassName(StartIconsWrapper)} {
        color: ${theme.colors.border.interactive.c500};
      }
    }
  }
`
