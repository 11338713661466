import React, {useState} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {Form, Formik} from 'formik'
import {WidgetLabelTypes} from 'utils/label'

import {Accordion} from '@daedalus/atlas/Accordion'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ClearFormButton} from './ClearFormButton'
import {CountrySelector} from './CountrySelector'
import {CurrencySelector} from './CurrencySelector'
import {DatePicker} from './DatePicker'
import {GenerateButton} from './GenerateButton'
import {GuestsPicker} from './GuestsPicker'
import {LabelInput} from './LabelInput'
import {LanguageSelector} from './LanguageSelector'
import {PartnerKeyInput} from './PartnerKeyInput'
import {SearchInput} from './SearchInput'
import {TitleInput} from './TitleInput'
import {WidgetTypeSelector} from './WidgetTypeSelector'

export const initialValues = {
  partnerKey: '',
  hotelIds: '',
  placeId: '',
  placeName: '',
  checkIn: '',
  country: '',
  checkOut: '',
  currency: '',
  language: '',
  rooms: '',
  label: '',
  title: '',
  widgetType: WidgetLabelTypes.hotelCard,
  destination: '',
  searchType: 'hotelNames'
}

const excludeFromQueryString = ['widgetType', 'destination', 'searchType']

export type WidgetGeneratorFormValues = typeof initialValues

interface Props {
  setWidgetType: (widgetType: WidgetLabelTypes) => void
  setPreviewSrc: (src: string) => void
}

const AdvancedSelectorsWrapper = styled('div')`
  width: 139px;
`

const ButtonsWrapper = styled(Grid)(
  ({theme}) =>
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      width: 482px;
      padding: ${theme.layout.spacing.s500}px ${theme.layout.spacing.s600}px;
      box-shadow: ${theme.shadows.fixedReverse};
    `
)

const ButtonContainer = styled('div')`
  width: 205px;
`

export const GeneratorForm = ({setWidgetType, setPreviewSrc}: Props) => {
  const [searchNames, setSearchNames] = useState<{id: number; name: string}[]>(
    []
  )

  const onSubmit = (values: WidgetGeneratorFormValues) => {
    const {widgetType, searchType, ...restValues} = values
    setWidgetType(widgetType)

    const queryString = Object.entries(restValues)
      .filter(
        ([key, value]) =>
          Boolean(value) && !excludeFromQueryString.includes(key)
      )
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

    let searchKey = 'hotelNames'

    if (searchType === 'placeName') {
      searchKey = 'placeName'
    }

    const searchValue = searchNames
      .map(item => encodeURIComponent(item.name))
      .join(',')
    const widgetUrl = `https://partners.vio.com/widgets/${widgetType}Widget/index.html?${queryString}&${searchKey}=${searchValue}`
    setPreviewSrc(widgetUrl)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <Grid container spacing="s000" direction="column" gap="s500">
          <PartnerKeyInput />
          <WidgetTypeSelector
            setSearchNames={setSearchNames}
            setPreviewSrc={setPreviewSrc}
          />
          <SearchInput
            searchNames={searchNames}
            setSearchNames={setSearchNames}
          />
          <Grid
            container
            direction="row"
            spacing="s000"
            wrap="no-wrap"
            justify="space-between"
          >
            <DatePicker />
            <GuestsPicker />
          </Grid>
          <Accordion
            header={
              <Text variant="labelS" colorPath="content.brand.c500">
                <FormattedMessageWrapper
                  id="widgetGenerator.advancedFilters"
                  defaultMessage="Advanced"
                />
              </Text>
            }
          >
            <Grid container spacing="s000" direction="column" gap="s600">
              <TitleInput />
              <LabelInput />
              <Grid
                container
                spacing="s000"
                direction="row"
                wrap="no-wrap"
                gap="s300"
              >
                <AdvancedSelectorsWrapper>
                  <CountrySelector />
                </AdvancedSelectorsWrapper>
                <AdvancedSelectorsWrapper>
                  <CurrencySelector />
                </AdvancedSelectorsWrapper>
                <AdvancedSelectorsWrapper>
                  <LanguageSelector />
                </AdvancedSelectorsWrapper>
              </Grid>
            </Grid>
          </Accordion>
          <ButtonsWrapper
            container
            spacing="s000"
            direction="row"
            gap="s600"
            alignItems="stretch"
          >
            <ButtonContainer>
              <ClearFormButton
                setSearchNames={setSearchNames}
                setPreviewSrc={setPreviewSrc}
              />
            </ButtonContainer>
            <ButtonContainer>
              <GenerateButton />
            </ButtonContainer>
          </ButtonsWrapper>
        </Grid>
      </Form>
    </Formik>
  )
}
