import cardValidator from 'card-validator'
import isBefore from 'date-fns/isBefore'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import {always, curry, defaultTo, find, not, propEq} from 'ramda'

import {CountryType} from '../../localization/types/Country'

// validators
export type ValidatorType = (arg0: string) => boolean

export type ValidationRuleType = {messageKey: string; validate: ValidatorType}[]

/**
 * Validates passed value as required
 *
 * @param string - any string value
 *
 * @returns {boolean} Boolean result of validation
 */
export const required: ValidatorType = value => Boolean(value)

/**
 * Validates if the passed value corresponds to a regular expression
 *
 * @param RegExp - Regular expression to check the string with
 * @param value - string which should be checked with regular expression
 * @returns {boolean} Boolean result of validation
 */
export const regex = curry((regex: RegExp, value: string): boolean =>
  new RegExp(regex).test(value)
)

/**
 * Checks if passed string is valid credit card number
 *
 * @param string - any string value
 *
 * @returns {boolean} Boolean result of checking
 */
export const validCreditCard: ValidatorType = v =>
  cardValidator.number(v).isValid

/**
 * Checks if passed string is valid phone number
 *
 * @param string - any string value
 *
 * @returns {boolean} Boolean result of checking
 */
export const validPhoneNumber: ValidatorType = v => /^[\d\-\s]+$/.test(v)

/**
 * Checks if passed string is valid month number
 *
 * @param string - any string value
 *
 * @returns {boolean} Boolean result of checking
 */
export const validMonth: ValidatorType = (month: string) =>
  /0[1-9]|1[012]$/.test(month)

/**
 * Checks if passed date is later than today
 *
 * @param {Date} date - date we need to check
 *
 * @returns {boolean} Boolean result of checking
 */
export const validDateInTheFuture = (date: Date) =>
  isBefore(lastDayOfMonth(new Date()), date)

export const convertTwoYearDigitsToFour = (year: string | number) => `20${year}`

/**
 * Checks if passed string is included in countries list
 *
 * @param {string} value - country code to check
 * @param countriesList - {@link CountryType[]} list
 * @returns {boolean} Boolean result of checking
 */
export const validCountryCode = (
  value: string,
  countriesList: CountryType[]
) => {
  const foundCountry = find(propEq('code', value), countriesList)
  return Boolean(foundCountry)
}

/**
 * Checks if passed string is valid postal code
 *
 * @param string - string to check
 *
 * @returns {boolean} Boolean result of checking
 */
export const validPostCode: ValidatorType = v =>
  /^\d{3,5}$|^[a-zA-Z\d]{2,5}([\s-]+)?[a-zA-Z\d]{2,5}$/.test(v)

/**
 * Checks if the given string is a valid postal code or an empty string
 *
 * @param string - postal code to check
 *
 * @returns {boolean} Boolean indicating is a valid postal code or an empty string
 */
export const validPostCodeV2: ValidatorType = v =>
  validPostCode(v) || not(required(v))

/**
 * Checks if passed string is valid email address
 *
 * @param string - string value to check
 *
 * @returns {boolean} Boolean result of checking
 */
export const validEmailAddress: ValidatorType = v =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/.test(
    v
  )
/**
 * Checks if passed string is empty or valid email address
 *
 * @param string - string value to check
 *
 * @returns {boolean} Boolean result of checking
 */
export const validOptionalEmailAddress: ValidatorType = v =>
  v.length === 0 || validEmailAddress(v)

/**
 * Checks if passed string is valid person name
 *
 * @param string - any string value
 *
 * @returns {boolean} Boolean result of checking
 */
export const validCompleteName: ValidatorType = v => {
  // Starts with a letter, then any char, at least one space and more chars
  return /^\D.* \D+/.test(v)
}
/**
 * Checks if the passed string starts with a letter
 *
 * @param string - any string value
 *
 * @returns {boolean} Boolean result of checking
 */
export const stringStartsWithNonDigit: ValidatorType = v => {
  // Only check for no numbers
  return /^\D.*/.test(v)
}

/**
 * Checks if passed string contains only allowed characters
 *
 * @param string - any string value
 *
 * @returns {boolean} Boolean result of checking
 */
// Allows ISO-8859-15 characters only.
export const validText: ValidatorType = v =>
  /^[\u0020-\u007E\u00A0-\u00A3\u00A5\u00A7\u00A9-\u00B3\u00B5-\u00B7\u00B9-\u00BB\u00BF-\u00FF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178]*$/.test(
    v
  )

/**
 * Checks if the passed string does not contain any special characters
 *
 * @param {string} value - string value to check
 *
 * @returns {boolean} Boolean result of checking
 */
export const validSpecialCharacters: ValidatorType = v =>
  /^[^\d!@#$%^&*()]*$/.test(v)

/**
 * Checks if the passed string satisfies the minimum length constraint
 *
 * @param {string} value - string value to check
 * @param {number} minLength - minimum length of the string
 *
 * @returns {boolean} Boolean result of checking
 */
export const validateMinStringLength = (minLength: number) => (value: string) =>
  minLength <= value.length

/**
 * Checks if the passed string satisfies the maximum length constraint
 *
 * @param {string} value - string value to check
 * @param {number} maxLength - maximum length of the string
 *
 * @returns {boolean} Boolean result of checking
 */
export const validateMaxStringLength = (maxLength: number) => (value: string) =>
  maxLength >= value.length

export type ValidationRulesType = Record<string, ValidationRuleType>

export type ValidationValueType<T = string> = (
  valueToValidate: T
) => string | null | undefined

export type ValidationMessageKeyType = (
  fieldName: string
) => ValidationValueType

export const getValidationMessageKey = curry(
  (
    validationRules: ValidationRulesType,
    fieldName: keyof ValidationRulesType,
    value: string
  ): string | null | undefined => {
    const validationFnOrDefault = defaultTo([
      {
        validate: always(true),
        messageKey: undefined
      }
    ])
    const validationRuleList = validationFnOrDefault(validationRules[fieldName])

    for (const validationRule of validationRuleList) {
      if (!validationRule.validate(value)) {
        return validationRule.messageKey
      }
    }

    return null
  }
)
