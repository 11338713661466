import React from 'react'
import {useIntl} from 'react-intl'
import {css, cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {toggle} from 'opticks'
import {SizesType} from 'types/Sizes'

import {
  CurrencyDisplay,
  formatAbbreviatedPriceValue,
  formatPriceValue
} from '@daedalus/core/src/price/business/formatPrice'
import {RoundingStrategy} from '@daedalus/core/src/utils/number'

import {cssTheme} from '../../../themes'
import {linariaMq} from '../../../utils/breakpoints'
import minus from '../../../utils/minus'
import {Lock} from '../helpers/Lock'
import {Icon} from '../Icon'

export type PriceSize = SizesType
export type LockSizeType = Extract<SizesType, 'sm' | 'md'>

interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** The currency code for the price */
  currency?: string
  /** Whether the price should be styled as an anchor price */
  isAnchor?: boolean
  /** Whether the price is hidden as it is locked */
  isLocked?: boolean
  /** Whether the price is a private deal */
  isPrivate?: boolean
  /** Whether the price is an app lock deal */
  isAppLocked?: boolean
  /** Price maximum decimal places */
  maximumFractionDigits?: number
  /** Price minimum decimal places */
  minimumFractionDigits?: number
  /** Whether the price should not be styled */
  noStyle?: boolean
  /** How the price should be rounded  */
  roundingStrategy?: RoundingStrategy
  /** Whether the locked icon can be shown. It is only shown when isPrivate is also true. */
  showLockIcon?: boolean
  /** Whether the app locked icon can be shown. It is only shown when isAppLocked is also true. */
  showAppIcon?: boolean
  /** Price size */
  size?: PriceSize
  /** The value to be displayed as the price */
  value: number
  /** Whether the price should be displayed in offer */
  shouldAlignOffer?: boolean
  /** Lock size */
  lockSize?: LockSizeType
  /** When true it will abbreviate the prices to max 6 characters, e.g. €12.3k or €123M. Note that k and M are not translated */
  isAbbreviated?: boolean
  /** When true it will abbreviate the currency symbol if it exists in ShortCurrencySymbols, otherwise it will not show the currency symbol. This only works if isAbbreviated is also true */
  useShortCurrency?: boolean
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** How to display the currency in currency formatting. i.e. 'symbol', 'narrowSymbol', 'code', 'name', */
  currencyDisplay?: CurrencyDisplay
}

const textStyles: Record<PriceSize, string> = {
  xs: css`
    ${cssTheme.typography.text.bodyXS}
  `,
  sm: css`
    ${cssTheme.typography.text.titleS}
  `,
  md: css`
    ${cssTheme.typography.text.titleM}
  `,
  lg: css`
    ${cssTheme.typography.text.titleL}
  `,
  xl: css`
    ${cssTheme.typography.text.titleXL}
  `,
  xxl: css`
    ${cssTheme.typography.text.title2XL}
  `
}

const StandardPriceElement = styled.div<{
  isPrivate?: boolean
  isAppLocked?: boolean
}>`
  display: inline-block;
  text-transform: uppercase;
  color: ${cssTheme.colors.price.default};
  &.--special {
    color: ${cssTheme.colors.price.special};
  }
`

const AnchorPriceElement = styled.div`
  color: ${cssTheme.colors.price.anchor};
  text-decoration: line-through;
  ${cssTheme.typography.text.bodyXS}

  &.--restyle {
    color: ${cssTheme.colors.content.neutral.c800};
    text-decoration: line-through;
    ${linariaMq.desktopXs} {
      ${cssTheme.typography.text.bodyS}
    }
  }
`

const Currency = styled.div`
  text-transform: uppercase;
`

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${cssTheme.layout.spacing.s200};

  &.--withMarginBottom {
    margin-bottom: ${minus(cssTheme.layout.spacing.s100)};
  }
`

export const Price = ({
  className,
  isAnchor = false,
  value,
  currency,
  noStyle = false,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0,
  isPrivate = false,
  roundingStrategy,
  isLocked = false,
  isAppLocked = false,
  showLockIcon = true,
  showAppIcon = false,
  size = 'lg',
  shouldAlignOffer = false,
  lockSize = 'md',
  isAbbreviated = false,
  useShortCurrency = false,
  dataId = '',
  currencyDisplay
}: Props) => {
  const {locale} = useIntl()
  const formattedNumber = isAbbreviated
    ? formatAbbreviatedPriceValue({
        locale,
        roundingStrategy,
        value,
        currency,
        useShortCurrency,
        currencyDisplay
      })
    : formatPriceValue({
        locale,
        roundingStrategy,
        value,
        currency,
        minimumFractionDigits,
        maximumFractionDigits,
        currencyDisplay
      })
  if (isAnchor) {
    return (
      <AnchorPriceElement
        className={cx(
          className,
          toggle('b4f4f3cc-website-restyle-v3', '', '--restyle')
        )}
        data-id={dataId}
        translate="no"
      >
        {formattedNumber}
      </AnchorPriceElement>
    )
  }

  return (
    <PriceWrapper
      className={cx(className, !!shouldAlignOffer && '--withMarginBottom')}
      data-id={dataId}
    >
      {isPrivate && value && showLockIcon && !isAppLocked && (
        <Lock isLocked={isLocked} size={lockSize === 'sm' ? 12 : 16} />
      )}
      {isAppLocked && value && showAppIcon && (
        <Icon name="Iphone" size="sm" colorPath="content.special.c500" />
      )}
      {noStyle ? (
        <Currency translate="no">{formattedNumber}</Currency>
      ) : (
        <StandardPriceElement
          isPrivate={isPrivate}
          isAppLocked={isAppLocked}
          className={cx(
            textStyles[size],
            (isPrivate || isAppLocked) && '--special'
          )}
          translate="no"
        >
          {formattedNumber}
        </StandardPriceElement>
      )}
    </PriceWrapper>
  )
}
