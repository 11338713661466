import React from 'react'
import {useIntl} from 'react-intl'
import {Field} from 'formik'

import {InputWrapperFormik} from '@daedalus/atlas/helpers/InputWrapperFormik'
import {InputText} from '@daedalus/atlas/InputText'
import {Label} from '@daedalus/atlas/Label'

export const LabelInput = () => {
  const intl = useIntl()

  const labelValue = intl.formatMessage({
    id: 'widgetGenerator.trackingLabel',
    defaultMessage: 'Widget label'
  })
  const placeholder = intl.formatMessage({
    id: 'widgetGenerator.trackingLabelPlaceholder',
    defaultMessage: 'Optional'
  })

  return (
    <Label value={labelValue} variant="bodyS">
      <InputWrapperFormik Field={Field} name="label">
        <InputText
          placeholder={placeholder}
          name="label"
          id="label"
          size="md"
        />
      </InputWrapperFormik>
    </Label>
  )
}
