import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import Settings from 'Settings'
import {WidgetLabelTypes} from 'utils/label'
import {getPropsFromSearch} from 'widgets/helpers/getSearchQueryFromProps'
import {getHotelsToShow} from 'widgets/helpers/hotel'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  WidgetSearchContext
} from '@daedalus/core/src/analytics/types/Events'
import {TaxDisplayLogic} from '@daedalus/core/src/offer/types/TaxDisplayLogic'

import {SearchRequest, SearchResponse} from '../types/partnersApi/types'

const baseUrl = Settings.get('REACT_APP_PARTNERS_ENDPOINT')

const buildHeaders = (partnerKey: string) => ({
  'X-Partner-Key': partnerKey
})

interface SearchQueryParams {
  partnerKey: string
  payload: SearchRequest
  widgetType?: WidgetLabelTypes
  taxDisplayLogic?: TaxDisplayLogic
}

// Define a service using a base URL and expected endpoints
export const partnersApi = createApi({
  reducerPath: 'partnersApi',
  baseQuery: fetchBaseQuery({baseUrl}),
  endpoints: build => ({
    searchHotels: build.query<SearchResponse, SearchQueryParams>({
      query({partnerKey, payload}) {
        return {
          url: '/v1/search',
          method: 'POST',
          body: payload,
          headers: buildHeaders(partnerKey)
        }
      },
      async onQueryStarted(
        {partnerKey, payload, widgetType},
        {dispatch, queryFulfilled}
      ) {
        const {query} = payload

        const props = query ? getPropsFromSearch({query}) : {}

        const widgetSearchContext: WidgetSearchContext = {
          checkIn: payload?.checkIn,
          checkOut: payload?.checkOut,
          placeId: props?.placeId,
          placeName: props?.placeName,
          country: props?.country,
          city: props?.city,
          hotelNames: props?.hotelNames,
          hotelIds: props?.hotelIds,
          rooms: payload?.rooms,
          language: payload?.language,
          currency: payload?.currency,
          userDevice: payload?.userDevice,
          userCountry: payload?.userCountry
        }

        dispatch(
          trackEvent({
            category: Category.System,
            entity: Entity.WidgetSearch,
            action: Action.Requested,
            analyticsContext: {
              [AnalyticsContext.WidgetSearchContext]: widgetSearchContext
            },
            payload: {
              partnerKey,
              widgetType,
              label: payload?.label
            }
          })
        )

        try {
          const {data} = await queryFulfilled

          // hotels data is currently only required for analyzing the multipleHotelsWithSearch widget
          const hotels =
            widgetType === WidgetLabelTypes.multipleHotelsWithSearch
              ? getHotelsToShow({hotels: data.hotels})
              : undefined

          dispatch(
            trackEvent({
              category: Category.System,
              entity: Entity.WidgetSearch,
              action: Action.Succeeded,
              analyticsContext: {
                [AnalyticsContext.WidgetSearchContext]: widgetSearchContext
              },
              payload: {
                partnerKey,
                widgetType,
                label: payload?.label,
                hotels,
                place: data?.place,
                checkIn: data?.stay?.checkIn,
                checkOut: data?.stay?.checkOut
              }
            })
          )
        } catch (error: any) {
          dispatch(
            trackEvent({
              category: Category.System,
              entity: Entity.WidgetSearch,
              action: Action.Errored,
              analyticsContext: {
                [AnalyticsContext.WidgetSearchContext]: widgetSearchContext
              },
              payload: {
                partnerKey,
                widgetType: WidgetLabelTypes.multipleHotelsWithSearch,
                label: payload?.label,
                errorMessage: error?.message
              }
            })
          )
        }
      }
    })
  })
})

export const {useSearchHotelsQuery} = partnersApi
